<template>
  <div>


    <!-- Filters -->

    <div v-if="store.mmData" class="row mmBorders mmHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-4">
        <div class="form-group">
          <!-- <fg-input label="Date Range" v-if="store.mmData && store.mmData.status_codes"> -->
          <fg-input label="Date Range">
            <el-date-picker v-model="store.mmDateRange" type="daterange"
                          class="daterange-mm"
                          @change="setSummaryData()"
                          :start-placeholder="startDate"
                          :end-placeholder="endDate"
                          :default-value="startDate"
                          :picker-options="pickerOptions1">
            </el-date-picker>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2">
        <div class="form-group">
          <!-- <fg-input label="Time Interval" v-if="store.mmData && store.mmData.status_codes"> -->
          <fg-input label="Time Interval">
            <el-select class="select-default mm-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.intervalSelected"
                       placeholder="All">
                  <el-option v-for="option in intervalOptions"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div> 
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-4">
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-vcenter">
        <!-- OG btn <a class="mundial-a" @click="resetMundialData()">Reset Filters</a> -->

        <button @click="resetMundialData()" class="btn btn-default btn-fill btn-wd btn-mundial-reset">Reset Filters</button> 

      </div>
    </div>


    <!--Stats cards-->


    <h6>433Futbol</h6>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6" v-for="stats in statsCards433">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="(stats.value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')">
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-lg-4 col-sm-6"> -->
      <div class="col-lg-6 col-sm-6">
        <chart-card :chart-data="threeChartData433"
                    chart-id="three-chart-433"
                    :chart-options="mundialOptions"
                    color="#2ba9cd" chart-title="3XX Codes per Time Interval">
          <span slot="title" style="font-size=1em;">{{ statsCards433[1].title }}</span>
          <badge slot="title-label" type="info">avg ( {{ threeChart433.data && Math.round(parseInt(statsCards433[1].value) / threeChart433.data.labels.length).toLocaleString("en-US") }} )</badge>

          <template slot="footer-title">Total: {{ statsCards433[1].value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</template>

        </chart-card>
      </div>

      <div class="col-lg-6 col-sm-6">
        <chart-card :chart-data="fourChartData433"
                    chart-id="four-chart-433"
                    :chart-options="mundialOptions"
                    color="#ef8156" chart-title="4XX Codes per Time Interval">
          <span slot="title">{{ statsCards433[2].title }}</span>
          <badge slot="title-label" type="danger">avg ( {{ fourChart433.data && Math.round(parseInt(statsCards433[2].value) / fourChart433.data.labels.length).toLocaleString("en-US") }} )</badge>

          <template slot="footer-title">Total: {{ statsCards433[2].value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</template>

        </chart-card>
      </div>
    </div>


    <h6>NextGenLatina</h6>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6" v-for="stats in statsCardsNGL">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="(stats.value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')">
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-lg-4 col-sm-6"> -->
      <div class="col-lg-6 col-sm-6">
        <chart-card :chart-data="threeChartDataNGL"
                    chart-id="three-chart-ngl"
                    :chart-options="mundialOptions"
                    color="#2ba9cd" chart-title="3XX Codes per Time Interval">
          <span slot="title" style="font-size=1em;">{{ statsCardsNGL[1].title }}</span>
          <badge slot="title-label" type="info">avg ( {{ threeChartNGL.data && Math.round(parseInt(statsCardsNGL[1].value) / threeChartNGL.data.labels.length).toLocaleString("en-US") }} )</badge>

          <template slot="footer-title">Total: {{ statsCardsNGL[1].value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</template>

        </chart-card>
      </div>

      <div class="col-lg-6 col-sm-6">
        <chart-card :chart-data="fourChartDataNGL"
                    chart-id="impressions-chart"
                    :chart-options="mundialOptions"
                    color="#ef8156" chart-title="4XX Codes per Time Interval">
          <span slot="title">{{ statsCardsNGL[2].title }}</span>
          <badge slot="title-label" type="danger">avg ( {{ fourChartNGL.data && Math.round(parseInt(statsCardsNGL[2].value) / fourChartNGL.data.labels.length).toLocaleString("en-US") }} )</badge>

          <template slot="footer-title">Total: {{ statsCardsNGL[2].value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</template>

        </chart-card>
      </div>
    </div>

  </div>
</template>
<script>
  import { DatePicker, Select, Option } from 'element-ui'
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import { Badge } from 'src/components/UIComponents'
  import { store } from 'src/store'
  
  const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
    callbacks: {
        label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
                label += ': ';
            }
            label += tooltipItem.yLabel.toLocaleString("en-US");
            return label;
        }
    }
  }, mundialOptions = {
    tooltips: tooltipOptions,
    scales: {
           yAxes: [{
               ticks: {
	           padding : 5,
                   fontColor: "#9f9f9f",
                   // Include a dollar sign in the ticks
                   callback: function(value, index, values) {
                       return new Intl.NumberFormat("en-US", {
                         notation: "compact",
                         compactDisplay: "short",
                       }).format(value);
                   },
               },
               gridLines: {
                   display: true,
                   drawBorder: true,
                   drawOnChartArea: false		   
               },
           }],
           xAxes: [{
	       display: true,
               ticks: {
	           padding : 5,
                   fontColor: "#9f9f9f",
                   callback: function(value, index, values) {
		       const maxIdx = values.length - 1;
		       var tickSpread = Math.floor(maxIdx / 10);
		       tickSpread = tickSpread === 0 ? 1 : tickSpread;

		       return index % tickSpread === 0 ? value : '';

                   },
               },
	       gridLines: {
	           display: true,
                   drawBorder: true,
                   drawOnChartArea: false
               },
           }],
       },
  }, mmDataset = {
       title: 'Mundial Media',
       borderColor: '#6bd098',
       pointBackgroundColor: '#6bd098',
       pointRadius: 2,
       pointHoverRadius: 6,
       borderWidth: 3,
       label: 'Data Zero',
       lineTension: 0,
       fill: false,
       data: []
  }, revenueOptions = JSON.parse(JSON.stringify(mundialOptions)),
  mmDate = store.mmDate(),
  maxDate = store.mmDate(),
  maxDateThresh = store.mmDate(),
  storeNameDict = {
    'date': 'mmDateRange',
    'product_type': 'productSelected',
    'product_subtype': 'subProductSelected',
    'billing_entity': 'companySelected',
    'site_name': 'siteSelected',
    'interval': 'intervalSelected',
  };

  // Default Dates
  maxDateThresh.setTime(maxDateThresh.getTime() - 3600 * 1000 * 24);

  //
  // Update revenueOptions
  //
  revenueOptions.tooltips.callbacks.label = function(tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label || '';

      if (label) {
          //label += ': $';
          label += ': ';
      }
      //label += tooltipItem.yLabel.toLocaleString("en-US");
      //label += tooltipItem.yLabel.toFixed(2);
      label += new Intl.NumberFormat("en-US",
        { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }
      ).format(tooltipItem.yLabel);
      
      return label;
  }
  revenueOptions.scales.yAxes[0].ticks.callback = function(value, index, values) {
      return '$' + new Intl.NumberFormat("en-US", {
          notation: "compact",
          compactDisplay: "short",
      }).format(value);
  }
  revenueOptions.scales.xAxes[0].ticks.callback = function(value, index, values) {
      const maxIdx = values.length - 1;
      var tickSpread = Math.floor(maxIdx / 10);
      tickSpread = tickSpread === 0 ? 1 : tickSpread;

      return index % tickSpread === 0 ? value : '';

  }

  export default {
    components: {
      StatsCard,
      ChartCard,
      Badge,
      //MundialTables,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        store,
        mundialOptions,
        revenueOptions,
        aggregateOptions: [{value:'total',label:'Total'},{value:'daily',label:'Daily'}],
        intervalOptions: [{value:5,label:'5'},{value:10,label:'10'},{value:15,label:'15'},{value:30,label:'30'},{value:60,label:'60'}],
        statsCards433: [
          {
            type: 'info',
            icon: 'nc-icon nc-globe',
            title: '2XX',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-tv-2',
            title: '3XX',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: '4XX',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
        ],
        threeChart433: {
          data: false
        },
        fourChart433: {
          data: false
        },
        fiveChart433: {
          data: false
        },
        statsCardsNGL: [
          {
            type: 'info',
            icon: 'nc-icon nc-globe',
            title: '2XX',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-tv-2',
            title: '3XX',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: '4XX',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
        ],
        threeChartNGL: {
          data: false
        },
        fourChartNGL: {
          data: false
        },
        fiveChartNGL: {
          data: false
        },
        pickerOptions1: {
          disabledDate (date) {
            return date > maxDateThresh
          },
          shortcuts: [{
            text: 'MTD',
            onClick (picker) {
              const monthToDate = irisDate.GetMonthToDate(),
              sDate = monthToDate['startDay'],
              eDate = monthToDate['endDay'] > maxDate ? maxDate : monthToDate['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              // Update Footer text
              store.irisText = 'MTD'
            }
          },
          {
            text: 'Last 7 Days',
            onClick (picker) {
              const lastSeven = mmDate.GetLastSeven(),
              sDate = lastSeven['startDay'],
              eDate = lastSeven['endDay'] > maxDate ? maxDate : lastSeven['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              // Update Footer text
              store.mmText = 'Last 7 Days'
            }
          },
          {
            text: 'Last 30 Days',
            onClick (picker) {
              const lastThirty = mmDate.GetLastThirty(),
              sDate = lastThirty['startDay'],
              eDate = lastAThirty['endDay'] > maxDate ? maxDate : lastThirty['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              // Update Footer text
              store.mmText = 'Last 30 Days'
            }
          },
          {
            text: 'Last Week',
            onClick (picker) {
              const lastWeek = mmDate.GetLastWeek(),
              sDate = lastWeek['startDay'],
              eDate = lastWeek['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              // Update Footer text
              store.mmText = 'Last Week'
            }
          },
          {
            text: 'Last Month',
            onClick (picker) {
              const lastMonth = mmDate.GetLastMonth(),
              sDate = lastMonth['startDay'],
              eDate = lastMonth['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              // Update Footer text
              store.mmText = 'Last Month'
            }
          }]
        },
        filters: [],
        mmText: 'Last 30 Days',
      }
    },
    methods: {
      reformatDate (tmpDate) {
        console.log({tmpDate})

        return tmpDate ? [tmpDate.split('-')[2], tmpDate.split('-')[0], tmpDate.split('-')[1]].join('-') : tmpDate
      },
      compileSummaryCharts () {
        const mundialData = store.mundialData,
        mundialSummary = {
          '433futbol': {
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
          },
          'nextgenlatina': {
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
          },
        }, dateDict = {},
        sigFig = 3,
        mmText = store.mmText,
        math_floor = Math.floor,
        math_round = Math.round,
        intervalSelected = store.intervalSelected;
        let dateArr = [], twoArr433 = [], threeArr433 = [],
        fourArr433 = [], fiveArr433 = [],
        twoArrNGL = [], threeArrNGL = [],
        fourArrNGL = [], fiveArrNGL = [];

        console.log({mundialData})
        console.log({store})
        
        for(let i = 0; i < mundialData.length; i++) {
          const tmpData = mundialData[i],
          tmpStatusCode = tmpData.target_status_code ? tmpData.target_status_code : tmpData.elb_status_code, tmpDomain = tmpData.site_domain,
          tmpDate = tmpData.date, tmpCounter = tmpData['page_counter'] && !isNaN(tmpData['page_counter']) ? tmpData['page_counter'] : 0;

          console.log({tmpData, tmpStatusCode, tmpDomain, tmpDate, tmpCounter})

          switch (tmpStatusCode) {
            case 200:
              mundialSummary[tmpDomain].two += tmpCounter;
              break;
            case 300:
              mundialSummary[tmpDomain].three += tmpCounter;
              break;
            case 400:
              mundialSummary[tmpDomain].four += tmpCounter;
              break;
            case 500:
              mundialSummary[tmpDomain].five += tmpCounter;
              break;
            default:
              break;
          }

          console.log({tmpDate})

          //
          // Convert Datetime
          //
          // END
          // Parse the datetime value and create a Date object
          const oldDate = new Date(tmpDate);

          // Subtract one day
          oldDate.setDate(oldDate.getDate() - 1);

          // Round down the minutes to the nearest 15 minutes
          const minutes = oldDate.getMinutes();
          const roundedMinutes = Math.floor(minutes / intervalSelected) * intervalSelected;

          // Set the minutes to the rounded value
          oldDate.setMinutes(roundedMinutes);
          oldDate.setSeconds(0); // Remove any seconds
          oldDate.setMilliseconds(0); // Remove any milliseconds

          console.log({oldDate, tmpData})

          // Convert back to ISO string (or any format you prefer)
          const newDate = oldDate.toISOString().split('T')[0];

          if ( newDate in dateDict ) {
             const dateIdx = dateDict[newDate];

             switch (tmpStatusCode) {
               case 200:
                 twoArr433[ dateIdx ] += tmpDomain === '433futbol' ? tmpCounter : 0;
                 threeArr433[ dateIdx ] += 0;
                 fourArr433[ dateIdx ] += 0;
                 fiveArr433[ dateIdx ] += 0;

                 twoArrNGL[ dateIdx ] += tmpDomain === 'nextgenlatina' ? tmpCounter : 0;
                 threeArrNGL[ dateIdx ] += 0;
                 fourArrNGL[ dateIdx ] += 0;
                 fiveArrNGL[ dateIdx ] += 0;
                 break;
               case 300:
                 twoArr433[ dateIdx ] += 0;
                 threeArr433[ dateIdx ] += tmpDomain === '433futbol' ? tmpCounter : 0;
                 fourArr433[ dateIdx ] += 0;
                 fiveArr433[ dateIdx ] += 0;

                 twoArrNGL[ dateIdx ] += 0;
                 threeArrNGL[ dateIdx ] += tmpDomain === 'nextgenlatina' ? tmpCounter : 0;
                 fourArrNGL[ dateIdx ] += 0;
                 fiveArrNGL[ dateIdx ] += 0;
                 break;
               case 400:
                 twoArr433[ dateIdx ] += 0;
                 threeArr433[ dateIdx ] += 0;
                 fourArr433[ dateIdx ] += tmpDomain === '433futbol' ? tmpCounter : 0;
                 fiveArr433[ dateIdx ] += 0;

                 twoArrNGL[ dateIdx ] += 0;
                 threeArrNGL[ dateIdx ] += 0;
                 fourArrNGL[ dateIdx ] += tmpDomain === 'nextgenlatina' ? tmpCounter : 0;
                 fiveArrNGL[ dateIdx ] += 0;
                 break;
               case 500:
                 twoArr433[ dateIdx ] += 0;
                 threeArr433[ dateIdx ] += 0;
                 fourArr433[ dateIdx ] += 0;
                 fiveArr433[ dateIdx ] += tmpDomain === '433futbol' ? tmpCounter : 0;

                 twoArrNGL[ dateIdx ] += 0;
                 threeArrNGL[ dateIdx ] += 0;
                 fourArrNGL[ dateIdx ] += 0;
                 fiveArrNGL[ dateIdx ] += tmpDomain === 'nextgenlatina' ? tmpCounter : 0;
                 break;
               default:
                 break;
             }
          } else {
             const dateIdx = dateArr.length;
             dateDict[ newDate ] = dateIdx

             switch (tmpStatusCode) {
               case 200:
                 twoArr433[ dateIdx ] = tmpDomain === '433futbol' ? tmpCounter : 0;
                 threeArr433[ dateIdx ] = 0;
                 fourArr433[ dateIdx ] = 0;
                 fiveArr433[ dateIdx ] = 0;

                 twoArrNGL[ dateIdx ] = tmpDomain === 'nextgenlatina' ? tmpCounter : 0;
                 threeArrNGL[ dateIdx ] = 0;
                 fourArrNGL[ dateIdx ] = 0;
                 fiveArrNGL[ dateIdx ] = 0;
                 break;
               case 300:
                 twoArr433[ dateIdx ] = 0;
                 threeArr433[ dateIdx ] = tmpDomain === '433futbol' ? tmpCounter : 0;
                 fourArr433[ dateIdx ] = 0;
                 fiveArr433[ dateIdx ] = 0;

                 twoArrNGL[ dateIdx ] = 0;
                 threeArrNGL[ dateIdx ] = tmpDomain === 'nextgenlatina' ? tmpCounter : 0;
                 fourArrNGL[ dateIdx ] = 0;
                 fiveArrNGL[ dateIdx ] = 0;
                 break;
               case 400:
                 twoArr433[ dateIdx ] = 0;
                 threeArr433[ dateIdx ] = 0;
                 fourArr433[ dateIdx ] = tmpDomain === '433futbol' ? tmpCounter : 0;
                 fiveArr433[ dateIdx ] = 0;

                 twoArrNGL[ dateIdx ] = 0;
                 threeArrNGL[ dateIdx ] = 0;
                 fourArrNGL[ dateIdx ] = tmpDomain === 'nextgenlatina' ? tmpCounter : 0;
                 fiveArrNGL[ dateIdx ] = 0;
                 break;
               case 500:
                 twoArr433[ dateIdx ] = 0;
                 threeArr433[ dateIdx ] = 0;
                 fourArr433[ dateIdx ] = 0;
                 fiveArr433[ dateIdx ] = tmpDomain === '433futbol' ? tmpCounter : 0;

                 twoArrNGL[ dateIdx ] = 0;
                 threeArrNGL[ dateIdx ] = 0;
                 fourArrNGL[ dateIdx ] = 0;
                 fiveArrNGL[ dateIdx ] = tmpDomain === 'nextgenlatina' ? tmpCounter : 0;
                 break;
               default:
                 break;
             }

             dateArr.push(newDate)
          }
        }
        
        console.log('_ _ _ _ _ _')
        console.log({mundialData, dateDict, dateArr, twoArr433, threeArr433, fourArr433, fiveArr433, twoArrNGL, threeArrNGL, fourArrNGL, fiveArrNGL, mundialSummary})
        console.log('_ _ _ _ _ _')

        this.statsCards433[0].value = ''
        this.statsCards433[1].value = ''
        this.statsCards433[2].value = ''
        this.statsCards433[0].value += mundialSummary['433futbol']['two'].toString()
        this.statsCards433[1].value += mundialSummary['433futbol']['three'].toString()
        this.statsCards433[2].value += mundialSummary['433futbol']['four'].toString()

        this.statsCardsNGL[0].value = ''
        this.statsCardsNGL[1].value = ''
        this.statsCardsNGL[2].value = ''
        this.statsCardsNGL[0].value += mundialSummary['nextgenlatina']['two'].toString()
        this.statsCardsNGL[1].value += mundialSummary['nextgenlatina']['three'].toString()
        this.statsCardsNGL[2].value += mundialSummary['nextgenlatina']['four'].toString()

        // Update Footer text
        this.statsCards433[0].footerText = mmText
        this.statsCards433[1].footerText = mmText
        this.statsCards433[2].footerText = mmText

        this.statsCardsNGL[0].footerText = mmText
        this.statsCardsNGL[1].footerText = mmText
        this.statsCardsNGL[2].footerText = mmText

        //
        // Replace missing dates
        //
        console.log({dateArr, dateArrLength:dateArr.length - 1, eDate:new Date( dateArr[dateArr.length - 1] ) })

        const sDate = new Date( dateArr[0] ), eDate = new Date( dateArr[dateArr.length - 1] ),
        diffDays = eDate ? store.dateDiffDays(sDate, eDate) : 0, boolCheck = !(dateArr.length) || (dateArr.length === diffDays + 1);

        if (!boolCheck) {
          const cleanDateArr = store.dateRangeArr(sDate, diffDays + 1),
          cleanThreeArr433 = new Array(diffDays + 1).fill(NaN),
          cleanFourArr433 = new Array(diffDays + 1).fill(NaN),
          cleanFiveArr433 = new Array(diffDays + 1).fill(NaN),
          cleanThreeArrNGL = new Array(diffDays + 1).fill(NaN),
          cleanFourArrNGL = new Array(diffDays + 1).fill(NaN),
          cleanFiveArrNGL = new Array(diffDays + 1).fill(NaN);

          for (let i = 0; i < cleanDateArr.length; i++) {
            const cleanDate = cleanDateArr[i],
            tmpDate = [cleanDate.split('-')[1], cleanDate.split('-')[2], cleanDate.split('-')[0]].join('-'),
            dateBool = tmpDate in dateDict,
            tmpThree433 = dateBool ? threeArr433[ dateDict[tmpDate] ] : false,
            tmpFour433 = dateBool ? fourArr433[ dateDict[tmpDate] ] : false,
            tmpFive433 = dateBool ? fiveArr433[ dateDict[tmpDate] ] : false,
            tmpThreeNGL = dateBool ? threeArrNGL[ dateDict[tmpDate] ] : false,
            tmpFourNGL = dateBool ? fourArrNGL[ dateDict[tmpDate] ] : false,
            tmpFiveNGL = dateBool ? fiveArrNGL[ dateDict[tmpDate] ] : false;

            console.log({dateBool, tmpDate, tmpThree433, tmpFour433, tmpFive433, tmpThreeNGL, tmpFourNGL, tmpFiveNGL})

            if (tmpThree433) cleanThreeArr433[i] = tmpThree433;
            if (tmpFour433) cleanFourArr433[i] = tmpFour433;
            if (tmpFive433) cleanFiveArr433[i] = tmpFive433;
            if (tmpThreeNGL) cleanThreeArrNGL[i] = tmpThreeNGL;
            if (tmpFourNGL) cleanFourArrNGL[i] = tmpFourNGL;
            if (tmpFiveNGL) cleanFiveArrNGL[i] = tmpFiveNGL;
          }

          dateArr = cleanDateArr;
          threeArr433 = cleanThreeArr433;
          fourArr433 = cleanFourArr433;
          fiveArr433 = cleanFiveArr433;
          threeArrNGL = cleanThreeArrNGL;
          fourArrNGL = cleanFourArrNGL;
          fiveArrNGL = cleanFiveArrNGL;

          console.log({dateArr, threeArr433, fourArr433, fiveArr433, threeArrNGL, fourArrNGL, fiveArrNGL})
        }

        //
        // 3XX
        //
        const threeData433 = {},
        threeDataset433 = JSON.parse(JSON.stringify(mmDataset));

        threeDataset433['borderColor'] = '#2ba9cd'
        threeDataset433['pointBackgroundColor'] = '#2ba9cd'
        threeDataset433['label'] = '3XX'
        threeDataset433['data'] = threeArr433

        threeData433['labels'] = dateArr
        threeData433['datasets'] = [ threeDataset433 ]
        this.threeChart433.data = threeData433

        const threeDataNGL = {},
        threeDatasetNGL = JSON.parse(JSON.stringify(mmDataset));

        threeDatasetNGL['borderColor'] = '#2ba9cd'
        threeDatasetNGL['pointBackgroundColor'] = '#2ba9cd'
        threeDatasetNGL['label'] = '3XX'
        threeDatasetNGL['data'] = threeArrNGL

        threeDataNGL['labels'] = dateArr
        threeDataNGL['datasets'] = [ threeDatasetNGL ]
        this.threeChartNGL.data = threeDataNGL

        //
        // Impressions
        //
        const fourData433 = {},
        fourDataset433 = JSON.parse(JSON.stringify(mmDataset));


        fourDataset433['borderColor'] = '#ef8156'
        fourDataset433['pointBackgroundColor'] = '#ef8156'
        fourDataset433['label'] = '4XX'
        fourDataset433['data'] = fourArr433

        fourData433['labels'] = dateArr
        fourData433['datasets'] = [ fourDataset433 ]
        this.fourChart433.data = fourData433

        const fourDataNGL = {},
        fourDatasetNGL = JSON.parse(JSON.stringify(mmDataset));


        fourDatasetNGL['borderColor'] = '#ef8156'
        fourDatasetNGL['pointBackgroundColor'] = '#ef8156'
        fourDatasetNGL['label'] = '4XX'
        fourDatasetNGL['data'] = fourArrNGL

        fourDataNGL['labels'] = dateArr
        fourDataNGL['datasets'] = [ fourDatasetNGL ]
        this.fourChartNGL.data = fourDataNGL

        //
        // Revenue
        //
        const fiveData433 = {},
        fiveDataset433 = JSON.parse(JSON.stringify(mmDataset));

        fiveDataset433['label'] = '5XX'
        fiveDataset433['data'] = fiveArr433

        fiveData433['labels'] = dateArr
        fiveData433['datasets'] = [ fiveDataset433 ]
        this.fiveChart433.data = fiveData433

        const fiveDataNGL = {},
        fiveDatasetNGL = JSON.parse(JSON.stringify(mmDataset));

        fiveDatasetNGL['label'] = '5XX'
        fiveDatasetNGL['data'] = fiveArrNGL

        fiveDataNGL['labels'] = dateArr
        fiveDataNGL['datasets'] = [ fiveDatasetNGL ]
        this.fiveChartNGL.data = fiveDataNGL
      },
      async setMinMaxMundial() {
        const mundial = store.mmData.status_codes, minIdx = 0, maxIdx = mundial.length - 1,
        tmpMin = new Date(mundial[minIdx].date), tmpMax = new Date(mundial[maxIdx].date),
        minDate = tmpMin < tmpMax ? tmpMin : tmpMax, maxDate = tmpMax > tmpMin ? tmpMax : tmpMin,
        filters = this.filters, filterMin = new Date(filters[0][1][0]), filterMax = new Date(filters[0][1][1]),
        customBool = store.customMinMaxDate.length,
        customMin = customBool ? store.customMinMaxDate[0] : false,
        customMax = customBool ? store.customMinMaxDate[1] : false,
        rangeBool = filterMin > customMin && filterMax < customMax,
        queryBool = customBool ? !rangeBool : false,
        sessionBool = store.mmData.sessionFilters;

        // Initial Min-Max
        if (!store.mmMinMaxDate.length) {
          store.mmMinMaxDate = [ minDate, maxDate ]
        }

        // Check for custom dateRange ( customRange )
        if (!customBool && filterMin < minDate) {
          //console.log({customBool})
          const adjMin = new Date(filterMin.getTime() - 3600 * 1000 * 24);

          store.customMinMaxDate = [ adjMin, filterMax ]
          store.customQuery = true
          store.mmData.custom = await store.customStatusCodes()
        }

        // Check if filterRange outside existing customRange
        if (queryBool && filterMin < minDate) {
          //console.log({queryBool})
          store.customMinMaxDate = [ filterMin, filterMax ]
          store.customQuery = true
          store.mmData.custom = await store.customStatusCodes()
        }

        // Check if filterRange inside existing customRange
        if (customBool && rangeBool) {
          store.customQuery = true
        }

        // Reset if within mmData.mundial
        if (filterMin > minDate) {
          store.customQuery = false
        }

        // Adjust customRange outside initial mmRange
        if (queryBool && sessionBool) {
          store.mmData.adjust_custom = true
        }

        //console.log({minIdx, maxIdx, minDate, maxDate, mundial, filterMin, filterMax, filters, mundialData:store.mundialData, customData:store.mmData.custom})
      },
      async setSummaryData() {
        store.mmLoader = true
        this.resetFilters()
        await this.setMinMaxMundial()

        console.log({filters:this.filters,mundial:store.mmData.mundial})
        console.log({store})

        if(this.filters.length){
          store.mundialData = (JSON.parse(JSON.stringify(store.filterMundial(this.filters, store.mmData.status_codes)))).sort(function(x, y){
            const date1 = new Date(x.date),
            date2 = new Date(y.date);
            return date1 - date2;
          })
        } else {
          store.mundialData = (JSON.parse(JSON.stringify(store.customQuery ? store.mmData.custom : store.mmData.status_codes))).sort(function(x, y){
            const date1 = new Date(x.date),
            date2 = new Date(y.date);
            return date1 - date2;
          })
        }

        console.log({mundialData:store.mundialData})
  
        this.compileSummaryCharts()
        this.mmSessionFilters()
        store.mmLoader = false
      },
      mmSessionFilters() {
        const sessionFilters = {},
        mmFilters = this.filters,
        sessionBool = store.mmData.sessionFilters,
        sessMin = sessionBool ? sessionBool.filters[0][1][0] !== mmFilters[0][1][0] : false,
        sessMax = sessionBool ? sessionBool.filters[0][1][1] !== mmFilters[0][1][1] : false,
        newRange = sessMin && sessMax,
        customBool = sessionBool ? newRange : true;

        //console.log({sessionBool,mmFilters})

        // Session filters (Django)
        sessionFilters['filters'] = mmFilters
        sessionFilters['mmText'] = store.mmText
        sessionFilters['aggregateSelected'] = store.aggregateSelected

        // Modify if customRange (outside mmRange)
        if (store.mmText === 'Custom Range' && customBool) {
          const customQuery = store.customQuery ? 0 : 1, tmpMin = new Date(sessionFilters['filters'][0][1][0]), strMin = new Date(tmpMin.getTime() + 3600 * 1000 * 24).toISOString().split('T')[0],
          tmpMax = new Date(sessionFilters['filters'][0][1][1]), strMax = new Date(tmpMax.getTime() + 3600 * 1000 * 24).toISOString().split('T')[0];

          sessionFilters['filters'][0][1] = [strMin, strMax]
        }

        //console.log({sessionFilters})

        this.setSessionFilters(sessionFilters)
      },
      resetFilters() {
        const dateRange = [store.mmDateRange[0], store.mmDateRange[1]],
        productType = store.productSelected, productSubtype = store.subProductSelected,
        billingEntity = store.companySelected,
        siteName = store.siteSelected,
        intervalTime = store.intervalSelected;

        // Data filters (VueJS)
        this.filters = []
        this.filters.push(['date', dateRange])
        //this.filters.push(['interval', intervalTime])
        //this.filters.push(['product_type', productType])
        //this.filters.push(['product_subtype', productSubtype])
        //this.filters.push(['billing_entity', billingEntity])
        //this.filters.push(['site_name', siteName])
      },
      resetMundialData() {
        store.mmText = 'Last 30 Days'
        store.mmDateRange = this.store.ogMundialDateRange
        store.intervalSelected = 15
        //store.companySelected = 'all'
        //store.siteSelected = 'all'
        //store.productSelected = 'all'
        //store.subProductSelected = 'all'
        //store.aggregateSelected = 'total'

        this.setSummaryData()
      },
      async setSessionFilters(params) {
        //console.log({params,store})
        await store.resetSessionFilters(params)
      },
    },
    async mounted () {
      await store.initStatusCodes()

      if (store.mmData) {
        // Adjust sessionRange dates only when outside mmRange
        store.mmData.adjust_custom = false

        // Reset sessionFilters
        store.mmData.sessionFilters = false
      }

      // Filter variables
      const lastSeven = mmDate.GetLastSeven(),
      sDate = lastSeven['startDay'].toISOString().split('T')[0],
      eDate = lastSeven['endDay'].toISOString().split('T')[0],
      sessionFilters = store.mmData.sessionFilters;

      console.log({sessionFilters})
      
      if (sessionFilters) {
        this.filters = sessionFilters.filters
        store.mmText = sessionFilters.mmText
        store.aggregateSelected = sessionFilters.aggregateSelected

        const filters = this.filters, filterLen = filters.length;
        for (let f = 0; f < filterLen; f++) {
          const tmpFilter = filters[f],
          tmpKey = tmpFilter[0],
          storeKey = storeNameDict[tmpKey];
          let tmpVal = tmpFilter[1];
          
          if (tmpKey === 'date' && store.mmText === 'Custom Range' && store.customQuery) {
            const tmpMin = new Date(tmpVal[0]), tmpMax = new Date(tmpVal[1]),
            adjMin = new Date(tmpMin.getTime() + 3600 * 1000 * 24),
            adjMax = new Date(tmpMax.getTime() + 3600 * 1000 * 24);

            //console.log({tmpMin,tmpMax,tmpVal,adjMin,adjMax})
            tmpVal = [adjMin, adjMax]
          }
          
          store[storeKey] = tmpVal 
        }
      } else {
        store.mmDateRange = [sDate, eDate]        
      }

      store.ogMundialDateRange = [sDate, eDate]
      
      // TEMP - commented out to allow render
      this.setSummaryData()
      // END

      console.log({store})
    },
    computed: {
      mmData () {
        return store.mmData
      },
      startDate () {
        return store.mmDateRange.length ? this.store.mmDateRange[0] : ''
      },
      endDate () {
        return store.mmDateRange.length ? this.store.mmDateRange[1] : ''
      },
      threeChartData433 () {
        return this.threeChart433.data
      },
      fourChartData433 () {
        return this.fourChart433.data
      },
      fiveChartData433 () {
        return this.fiveChart433.data
      },
      threeChartDataNGL () {
        return this.threeChartNGL.data
      },
      fourChartDataNGL () {
        return this.fourChartNGL.data
      },
      fiveChartDataNGL () {
        return this.fiveChartNGL.data
      },
      siteOptions () {
        const companySelected = store.companySelected === 'all' ? false : store.mmData.be_options.filter(obj => obj.label.toLowerCase() === store.companySelected)[0],
        be_id = companySelected ? companySelected.value : false,
        be_sn_set = be_id ? new Set(store.mmData.be_sn_dict.filter(obj => obj.be_id === be_id)[0].sn_id.split(',')) : false;

        //if (be_sn_set) {
          //store.siteSelected = 'all'
          //this.setSummaryData()
        //}

        return be_sn_set ? store.mmData.site_options.filter(obj => be_sn_set.has(obj.value.toString())) : store.mmData.site_options
      },
      subProductOptions () {
        const productSelected = store.productSelected === 'all' ? false : store.mmData.product_options.filter(obj => obj.label.toLowerCase() === store.productSelected)[0],
        product_id = productSelected ? productSelected.value : false,
        subproduct_list = store.mmData.product_subproduct_dict.filter(obj => obj.product_type_id === product_id),
        product_subproduct_set = product_id && subproduct_list.length ? new Set(subproduct_list[0].product_subtype_id.split(',')) : false;

        //if (product_subproduct_set) {
          //store.subProductSelected = 'all'
          //this.setSummaryData()
        //}

        return product_subproduct_set ? store.mmData.subproduct_options.filter(obj => product_subproduct_set.has(obj.value.toString())) : product_id && !subproduct_list.length ? [] : store.mmData.subproduct_options
      },
    },
  }

</script>
<style>
.mmBorders {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.mmHeader {
    border-top: none;
    margin-top: -1em;
    margin-bottom: 3em;
}
.mm-default.el-select .el-input input {
    background-color: #fff;
    border-color: #ddd!important;
    border-width: 2px;
    border-radius: 12px;
    color: #66615b;
}
.el-date-editor .el-range__close-icon {
    display: none;
}
.select-default.el-select .el-input .el-input__icon {
    border-radius: 20px;
    height: 36px;
    width: 30px;
    line-height: 36px;
}
.select-default.el-select-dropdown__item.selected, .select-default.el-select-dropdown__item.selected.hover {
    background-color: #ddd;
    color: #66615b;
}
.el-range-editor.is-active, .el-range-editor.is-active:hover {
    border-color: #9a9a9a;
}
.daterange-mm {
    border-radius: 12px !important;
    border: 2px solid #ddd !important;
}
.mm-vcenter {
    display: table;
}
.mm-vcenter button {
    margin-top: 1em;
}
.mm-vcenter > .mundial-a {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}
.mm-vcenter > .btn-mm-reset {
    float: right;
}
.btn-mm-reset:hover, .btn-mm-reset:focus {
    background-color: #66615b !important;
}
.btn-mm-avails:hover, .btn-mm-avails:focus {
    background-color: #51bcda !important;
}
</style>
